<template lang='pug'>
	#btn-up.dropdown-custom
		b-btn.text-nowrap.text-default(variant='outline-primary' @click='click' size='sm' :class='{"d-none" : !isVisible}')
			| {{ $t('forms.up') }}
			.dropdown-toggle
</template>
<script>

import $ from 'jquery'

export default {
	name: 'PwBtnUp',
	data: () => ({
		isVisible: false,
	}),
	methods: {
		click () {
			window.scrollTo(0, 0);
        },
		onScroll ( e ) {
			this.isVisible = $(window).scrollTop() > 100;
		}
	},
	mounted () {
		window.addEventListener('scroll', this.onScroll);
	},
	destroyed () {
		window.removeEventListener('scroll', this.onScroll);
	}
}
</script>
<style lang='scss' scoped>
	#btn-up {
		position: fixed;
		bottom: 75px;
		right: 195px;
		z-index: 9;

		.btn {
			padding: 12px 36px 12px 12px;
		}
		.dropdown-toggle {
			&::after {
				border-color: var(--primary-color);
				transform: rotate(-135deg);
				top: 18px;
			}
		}
	}

	@media (max-width: 767.98px) {
		#btn-up {
			top: auto;
			left: auto;
			bottom: 80px;
			right: 24px;
		}
	}
</style>